.climate-contribution {
  background-color: var(--primary-green);
  padding: 2.5rem;
  color:white;
  font-weight: bold;
  position: relative;
}

.climate-contribution .container {
  display: flex;
  gap: 2.5rem;
}
.climate-content {
  max-width: 40rem;
}
.climate-content h4 {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.climate-contribution p {
  padding-bottom: 1rem;
  line-height: 1.25rem;
}
.climate-contribution p>em {
  color:var(--primary-red);
  font-weight: bold;
}
.climate-contribution img {
  background-image: url("data:image/svg+xml,%3Csvg width='188' height='81' viewBox='0 0 188 81' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 49.7489C13.3528 43.1893 37.8349 31.3123 56.8447 34.3483C83.6311 38.6262 107.825 53.1711 136.34 48.0377C159.151 43.9309 176.375 31.4964 183 24.9369M24.8738 11.2475C33.5146 7.5399 55.1169 -0.732044 84.4951 11.2475C122.515 26.7505 143.54 13.2437 151.029 8.68053M28.3301 70.2825C36.9709 66.0059 58.1785 60.294 78.4466 70.2825C92.3381 77.1285 108.689 77.9841 124.243 71.995' stroke='%23ED7208' stroke-width='9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  border-radius: 1rem;
}
.climate-contribution .img-container{
  position: relative;
  
}
.climate-contribution .img-container::after {
  content: "";
  position: absolute;
  left: -40px;
  top: 70%;
  background-image: url("data:image/svg+xml,%3Csvg width='188' height='81' viewBox='0 0 188 81' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 49.7489C13.3528 43.1893 37.8349 31.3123 56.8447 34.3483C83.6311 38.6262 107.825 53.1711 136.34 48.0377C159.151 43.9309 176.375 31.4964 183 24.9369M24.8738 11.2475C33.5146 7.5399 55.1169 -0.732044 84.4951 11.2475C122.515 26.7505 143.54 13.2437 151.029 8.68053M28.3301 70.2825C36.9709 66.0059 58.1785 60.294 78.4466 70.2825C92.3381 77.1285 108.689 77.9841 124.243 71.995' stroke='%23ED7208' stroke-width='9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  height: 40px;
  width: 81px;
  background-size: 81px;
}

.climate-contribution::before, .climate-contribution::after {
  content: "";
    position: absolute;
    top: -33px;
    left: -33px;
    background-image: url(../images/new/green-separator.png);
    background-repeat: repeat-x;
    height: 67px;
    width: calc(100% + 33px);
    background-size: auto 50px;
}
 .climate-contribution::after {
    top: unset;
    left: -33px;
    bottom: -33px;
}

@media (max-width: 767px) {
  .climate-contribution .container {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .climate-contribution {
    padding: 30px 5px;
  }
}