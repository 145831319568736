.hero {
  margin-top: -5rem;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-bottom: -15vh;
  color: white;
  padding: 0;
}
.heroBannerInfos {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.heroBannerSlider {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  clip-path: circle(400vh at 50% -300vh);
  top: 0;
}
.heroBannerSlider .slick-active a {
  color: white !important;
}
.heroBannerSlider div:not(.heroBannerInfos) {
  position: relative;
  height: 100%; 
  width: 100%;
}
.heroBannerSlider .slick-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.heroBannerSlider .slick-prev {
  left: 25px;
}
.heroBannerSlider .slick-next {
  right: 25px;
}
.heroBannerSlider .slick-arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.heroBannerSlider .slick-arrow.slick-prev::before {
  background-image: url(../images/new/prev-white.png);
}
.heroBannerSlider .slick-arrow.slick-next::before {
  background-image: url(../images/new/next-white.png);
}
.heroBannerSlider .slick-arrow::before {
    content: '';
    width: 18px;
    height: 30px;
    background-size: contain;
}
.hero h1 {
  text-align: center;
  font-size: clamp(2.5rem, 0.0769rem + 11.0769vw, 7rem);
  margin-bottom: 2rem;
  line-height: 0.8;
  font-family: "Abril Fatface", serif;
  text-transform: uppercase;
  color:white;
}
.hero h2 {
  text-align: center;
  padding: 0 40px;
}
.hero .search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(17px);
  padding: 0.5rem;
  border-radius: 3rem;
}
.search-input {
  background-color: #fff;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
}

.search-input svg:nth-child(1) {
  margin-right: 0.5rem;
}
.search-input input {
  border: none;
}
.search-input input::placeholder {
  font-size: 0.75rem;
}

#location {
  width: 32ch;
}

.search-input input:focus-visible {
  outline: none;
}

.departure-search {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.search-label:has(input:focus) {
  border-bottom-left-radius: 0;
}

.destination-result {
  font-family: Raleway;
  border-top: 1px solid grey;
  padding: 0.5rem;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  max-height: 150px;
  height: fit-content;
  top: 100%;
  left: 0px;
  z-index: 1;
  background-color: white;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  visibility: hidden;
  overflow: scroll;
}

.search-label:has(input:focus) .destination-result {
  visibility: visible;
}
.destination-result:empty {
  background-color: red;
  height: 3rem;
}
.destination-result h6 {
  color: #051929;
  font-weight: 700;
  padding-left: 0.25rem;
  padding-bottom: 0.5rem;
  line-height: 1.25rem;
}
.destination-result ul li {
  color: #051929;
  line-height: 1.25rem;
  padding-block: 0.25rem;
  padding-left: 1rem;
  cursor: pointer;
}
.destination-result ul li:hover {
  box-shadow: -20px 0px 0 0px lightgrey, 20px 0px 0 0px lightgrey;
  background: lightgrey;
}
.search-bar > .cta {
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.hero .search-bar svg {
  width: 24px;
}

.hero video {
  /* position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  clip-path: circle(400vh at 50% -300vh); */
}
.or-spacing {
  margin: 1rem auto;
  font-weight: bold;
}

@media (max-width: 991px) {
  .hero {
    margin-top: 0;
  }
  .heroBannerSlider .slick-prev {
    left: 2px;
  }
  .heroBannerSlider .slick-next {
    right: 2px;
  }
  .heroBannerSlider .slick-arrow::before {
    width: 15px;
    height: 24px;
  }
}

@media (max-width: 750px) {
  .hero .search-bar {
    flex-direction: column;
    border-radius: 2rem;
  }
  .hero .search-bar label,
  .hero .search-bar .cta {
    border-radius: 1.5rem;
    width: 100%;
    box-sizing: border-box;
  }
  #departure {
    width: 100%;
  }
  .search-label:has(input:focus) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}