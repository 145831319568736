footer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3Csvg width='300' height='206' viewBox='0 0 300 206' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-140 125.145C-120.197 109.439 -62.1553 81.0013 -17.0874 88.2705C46.4175 98.5134 103.777 133.339 171.379 121.048C225.46 111.215 266.294 81.4421 282 65.7362M-92.8835 32.9587C-72.3981 24.0815 -21.1836 4.27539 48.466 32.9587C138.602 70.0787 188.45 37.7385 206.204 26.8125M-84.6893 174.31C-64.2039 164.07 -13.9252 150.394 34.1262 174.31C67.06 190.702 105.825 192.751 142.699 178.411' stroke='%2358B99D' stroke-opacity='0.1' stroke-width='35' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  padding: 1rem 3rem;
}
footer > * {
  flex: 2 2;
}
footer a {
  color: black;
}
footer .logo {
  height: auto;
  width: 200px;
}
footer .contacts {
   flex: 3 3;
  }
footer .contacts .contact-phone {
  font-weight: bold;
  line-height: 1.5;
}
footer .contacts .contact-phone .small {
  margin-bottom: 1rem;
  font-size: 0.675rem;
}

footer .list h6 {
  font-weight: bold;
  margin-bottom: 1.25rem;
}
footer .list {
  min-width: 150px;
}
footer .list ul li {
  margin-bottom: 0.875rem;
}
footer .footer__social {
    display: flex;
    gap:0.5rem;
}
footer .footer__social svg {
    color: var(--primary-red);
  width: 26px;
  height: 26px;

}

.footer-copyright {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    flex-wrap: wrap;
}
.footer-copyright a  {
color: var(--primary-green);
}

@media (max-width: 575px) {
  footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
