.expeditions {
  margin-top: 5rem;
  position: relative;
  padding-top: 4rem;
  margin-bottom: 3rem;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .expeditions .expeditions-details iframe{
    width: 100%;
    height: 200px;
  }
}

.expeditions:after {
  content: "";
  position: absolute;
  height: 197px;
  width: 380px;
  right: 0;
  z-index: -1;
  top: 30%;
  background-image: url("data:image/svg+xml,%3Csvg width='380' viewBox='0 0 583 303' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 185.239C52.1731 161.31 140.612 117.984 209.282 129.059C306.044 144.665 393.442 197.723 496.447 178.997C578.85 164.016 641.07 118.656 665 94.7275M93.7913 44.79C125.005 31.2653 203.04 1.09015 309.165 44.79C446.505 101.343 522.458 52.0722 549.51 35.4261M106.277 260.143C137.49 244.543 214.1 223.706 287.316 260.143C337.497 285.117 396.563 288.238 452.748 266.39' stroke='white' stroke-opacity='0.4' stroke-width='44' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.circled-clip-bg {
  top: 0;
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #e5f0ee;
  clip-path: circle(400vh at 50% -305vh);
}
.expeditions::before {
  content: "";
  position: absolute;
  top: -33px;
  left: -33px;
  background-image: url(../images/new/light-green-separator.png);
  background-repeat: repeat-x;
  height: 67px;
  width: calc(100% + 33px);
  background-size: auto 50px;
}
.expeditions > h3 {
  line-height: 0;
}
.expeditions h3 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}

.expeditions__explaination {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-inline: 2rem;
  margin-bottom: 4rem;
}
.expeditions__image {
  flex: 4 4;
  min-width: 300px;
}
.expeditions__image img {
  object-fit: contain;
}
.expeditions__content {
  flex: 3 3;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expeditions__content h4 {
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.expeditions__content p {
  margin-bottom: 1rem;
  line-height: 1.25;
}

/* Previous expeditions */
.previous-expeditions {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.previous-expeditions__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

.previous-expeditions__grid .expedition__item h5 {
  color: white;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  font-weight: bold;
  z-index: 3;
  padding: 2rem 1rem;
  top: 0;
  font-size: 1.25rem;
}

.previous-expeditions__grid .expedition__item {
  border: none;
  padding: 0;
  max-width: 375px;
  min-width: 220px;
  border-radius: 1rem;
  flex: 1 1;
  aspect-ratio: 8/7;
  overflow: hidden;
  position: relative;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previous-expeditions__grid .expedition__item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
.previous-expeditions__grid .expedition__item:after {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='55' height='55' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_349_3348)'%3E%3Crect width='70' height='70' rx='35' fill='%23ED7208'/%3E%3C/g%3E%3Cpath d='M48.9048 33.4077L29.2102 18.4397C27.8933 17.4389 26 18.3781 26 20.0321V49.9679C26 51.6219 27.8933 52.5611 29.2102 51.5603L48.9048 36.5923C49.9579 35.792 49.9579 34.208 48.9048 33.4077Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_349_3348' x='-54' y='-54' width='178' height='178' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='27'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_349_3348'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_349_3348' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  
  width: 55px;
  height: 55px;
}
dialog.expeditions-details {
border-radius: 0.5rem;
border: none;
}
dialog.expeditions-details::backdrop {
  background-color:grey;
  opacity: 0.55;
}



@media (max-width: 767px) {
  .circled-clip-bg {
    clip-path: circle(400vh at 50% -258vh);
  }
}

@media (max-width: 575px) {
  .expeditions__explaination,
  .previous-expeditions {
    padding-left: 20px;
    padding-right: 20px;
  }
  .previous-expeditions__grid {
    padding-left: 0px;
    padding-right: 0px;
  }
}
