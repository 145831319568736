@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.map-display {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/new/map-background.webp);
  background-size: cover;
  padding: 4rem;
  padding-top: 2rem;
  flex-direction: column;
}
/* MAP destination selector */
.map-display .departure-selector {
  margin: auto;
  justify-content: center;
  font-size:22px;
}
.map-display .destination-selector,
.map-display .departure-selector {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  font-weight: bold;
}

.map-display .destination-selector input,
.map-display .departure-selector input {
  display: none;
}


.map-display .destination-selector label,
.map-display .departure-selector label {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  padding: 1rem;
  border-bottom: 1px solid white;
  margin-bottom: 0;
  background-color: #112e4a;
  color: white;
  cursor: pointer;
}
.map-display .destination-selector label{
  
  padding: 1rem 0.5rem;
  flex-grow: 1;
}
.map-display .destination-selector input:checked + label,
.map-display .departure-selector input:checked + label 
{
  background-color: white;
  color: #58b99d;
}

.map-departure:first-of-type {
margin-left: 1rem;
}

.map-display .destination-selector label:first-of-type, 
.map-display .departure-selector label:first-of-type 
{
  border-top-left-radius: 0.8rem;
}
.map-display .destination-selector label:not(:first-of-type),
.map-display .departure-selector label:not(:first-of-type)
 {
  border-left: 1px solid white;
}
.map-display .destination-selector label:last-of-type,
.map-display .departure-selector label:last-of-type {
  border-top-right-radius: 0.8rem;
}

.map-display
  .destination-selector:has(input:checked#ocean-indien)
  + .map
  .pin:not([data-destination="ocean-indien"]),
.map-display
  .destination-selector:has(input:checked#mer-rouge)
  + .map
  .pin:not([data-destination="mer-rouge"]),
.map-display
  .destination-selector:has(input:checked#bassin-indo-pacifique)
  + .map
  .pin:not([data-destination="bassin-indo-pacifique"]),
.map-display
  .destination-selector:has(input:checked#ocean-pacifique)
  + .map
  .pin:not([data-destination="ocean-pacifique"]),
.map-display
  .destination-selector:has(input:checked#caraibes)
  + .map
  .pin:not([data-destination="caraibes"]),
.map-display
  .destination-selector:has(input:checked#ocean-atlantique)
  + .map
  .pin:not([data-destination="ocean-atlantique"]),
.map-display
  .destination-selector:has(input:checked#mer-mediteranee)
  + .map
  .pin:not([data-destination="mer-mediteranee"]) {
  transition: opacity 300ms, visibility 0s 300ms;
  visibility: hidden;
  opacity: 0;
}

.map-display .departure-selector:has(input:checked#france) ~ .map .pin:not([data-departure*="france"]),
.map-display .departure-selector:has(input:checked#belgique) ~ .map .pin:not([data-departure*="belgique"]),
.map-display .departure-selector:has(input:checked#suisse) ~ .map .pin:not([data-departure*="suisse"]),
.map-display .departure-selector:has(input:checked#luxembourg) ~ .map .pin:not([data-departure*="luxembourg"]) {
  transition: opacity 300ms, visibility 0s 300ms;
  visibility: hidden;
  opacity: 0;
}
/* END MAP destination selector */

.map-display .map {
  background: rgba(255, 255, 255, 0.36);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 1);
}
.map-display img {
  width: 100%;
}
.map-display .map-display__modal {
  width: 4px;
  transform: scaleY(0);
  max-width: fit-content;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  height: fit-content;
  background-color: white;
  display: grid;
  grid-template-columns: 7rem auto;
  grid-auto-rows: auto;
  background-color: #232334;
  color: white;
}
.map-display .map-display__modal:after {
  background-color: #58b99d;
  width: 4px;
  height: 100%;
  content: "";
  left: 0px;
  position: absolute;
}

.map-display .map-display__modal .modal__content {
  padding: 1rem;
  height: fit-content;
}
.map-display .map-display__modal .modal__content .modal__title {
  font-size: 1rem;
  font-family: "Abril Fatface", serif;
  position: relative;
}
.map-display .map-display__modal .modal__content .modal__title:after {
  content: "";
  margin-block: 0.25rem 0.5;
  min-width: 91px;
  width: 100%;
  aspect-ratio: 6/1;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='91' height='12' viewBox='0 0 91 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.03209 9.39708C8.24148 5.01288 24.7012 -1.63121 41.8135 4.23124C65.4642 12.3336 80.0411 11.0063 89.2627 6.42786' stroke='%23ED7208' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
.map-display .map-display__modal .modal__content .modal__description {
  font-size: 0.75rem;
}
.map-display .map-display__modal .modal__image {
  background-size: cover;
  width: 18rem;
}

/* Pin relative Style  */
.map-display .pin {
  cursor: pointer;
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #58b99d;
  box-shadow: 0 0 0px 5px #58b99d51, 0 0 0px 5px transparent;
  top: 50%;
  left: 50%;
  transition: box-shadow 300ms;
}
.map-display .pin > .map-display__modal {
  transition: width 1s 500ms, transform 300ms;
}
.map-display .pin:hover {
  box-shadow: 0 0 0px 8px #58b99d51, 0 0 0px 4px #58b99d51;
}
.map-display .pin:hover > .map-display__modal,
.map-display .pin:focus > .map-display__modal {
  opacity: 1;
  transform: scaleY(1);
  width: 32rem;
  height: 10rem;
  display: flex;

  /*   width: 300px; */

}
.map-display .pin:nth-of-type(2) {
  top: 47%;
  left: 22.5%;
}
.map-display .map-display__modal.pin--bottom {
  transform-origin: bottom;
  bottom: 14px;
}
.map-display .map-display__modal.pin--right {
  right: 6px;
}
.map-display .map-display__modal.pin--right:after {
  right: 0px;
  left: unset;
}
.map-display .map-display__modal.pin--top {
  transform-origin: top;
  top: 14px;
}
.map-display .map-display__modal.pin--left {
  left: 6px;
}

@media (max-width: 991px) {
  .map-display {
    padding: 4rem 0;
  }
}