@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

.grid-album {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 160px 50px 160px;
  gap: 1rem;
  font-family: Raleway;
  padding: 1rem;
  margin-bottom: 2rem;
}

.grid-album .image-container {
  min-width: 150px;
  max-height: calc(210px + 1rem);
  position: relative;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  transition: transform 300ms;
}
.grid-album .image-container a{
  height:100%;
  width:100%;
  z-index:1;
}

.grid-album .image-container:hover {
  transform: scale(1.025);
}
.grid-album .image-container:after {
  content: "";
  background: linear-gradient(transparent, #0000008a);
  position: absolute;
  inset: 0;
}
.grid-album .col-2 {
  grid-column: span 2;
  min-width: 300px;
  max-width: unset;
  padding:unset;
}

.grid-album .row-2 {
  grid-row: span 2;
}
.grid-album .image-container .image__text {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  align-items: center;
  color: white;
  bottom: 0;
  padding: 1rem;
}
.grid-album .image-container .image__text .title {
  text-align: center;
  width: 100%;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  font-weight: 700;
}
.grid-album .image-container .image__text .description {
  font-size: clamp(0.7rem, 1vw, 0.9rem);
  line-height: 1.1rem;
  font-weight: 700;
  max-width: 90%;
}


@media (max-width: 675px){

  .grid-album {
    display: flex;
    overflow: scroll;
    flex-wrap: nowrap;
  }
  .grid-album .image-container{
    min-width: 300px;
  }
}
